.buttonsBox {
    margin-left: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    top: 80%;
    bottom: 0;
    position: absolute;
    z-index: 300000;
}

.mainBox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.singleButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 95%;
    top: 80%;
    bottom: 0;
    position: absolute;
    z-index: 300000;
}

.textBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    width:85%;
    height: 15%;
    top: 5%;
    position: absolute;
    z-index: 300000;
}

.uploadedImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 55%;
    max-width: calc(100% - 30px);
    z-index: 3;
}

.imageContainer {
    max-width: 100%;
    align-self: center;
    z-index: 3;
}

.explainText {
    margin-top: 30px;
}

.scanning {
    background-size: cover;
    z-index: 1;
}

.canvasMask {
  overflow: hidden;
    position: absolute;
    z-index: 7;
}

.hiddenCanvas {
    display: none;
}

@media (max-width: 950px) {
    .textBox {
        top: 1%;
        height: 10%;
    }

}

@media (max-width: 450px) {
    .textBox {
        top: 2%;  
        height: 15%;   
    }
}
