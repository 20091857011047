
html,
body,
#root {
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    background: #1C1C1C;
    overflow: auto;
}

