.buttonsBox {
    margin-left: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    top: 80%;
    bottom: 0;
    position: absolute;
    z-index: 300000;
}

.singleButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 95%;
    top: 80%;
    bottom: 0;
    position: absolute;
    z-index: 300000;
}