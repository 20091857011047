.mainBox {
    height: 100%;
    width: 100%;
}

.buttonsBox {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    top: 80%;
    position: absolute;
    z-index: 300000;
}

@media (min-width: 850px) {
    .buttonsBox {
        width: 60%;
        align-self: center;
    }
}

@media (max-width: 412px) {
    .buttonsBox {
        align-self: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 95%;
        top: 80%;
        position: absolute;
        z-index: 300000;
    }
}