.mainBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 900px;
    width: 100%;
    height: 100%;
}

.formBox {
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
}

.buttonBox {
    display: flex;
    align-self: center;
    margin-top: 60px;
    margin-left: 8px;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    width: 90%;
    margin-left: 60px;
    max-height: 850px;
    min-height: 700px;
    height: 100%;
 }

@media (max-width: 950px) {
    .formBox {
        width: 70%;
    }
    .container {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-height: 800px;
        min-height: 650px;
        margin: 0;
    }
    .buttonBox {
        display: flex;
        width: 70%; 
        justify-content: flex-end;
        margin: 0;
    }
}

@media (max-width: 480px) {
    .mainBox {
        max-height: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
        gap: 7em;
    }
    .container {
        flex-direction: column;
        max-height: 100%;
        min-height: 60%;
        height: 70%;
        margin: 0;
        
    }
    .buttonBox {
        width: 95%;
        top: 80%;
        position: absolute;
    }
    .formBox {
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center; 
        flex-direction: column;
    }
}

@media (max-width: 480px) and (max-height: 750px) {
    .mainBox {
        gap: 2em;
    }
}