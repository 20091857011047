.mainBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 900px;
    height: 800px;
    width: 600px;
    align-self: center;
}

.link {
    text-decoration: none;
    color: #5965ED;
}

@media (max-width: 480px) {
    .mainBox {
        max-height: 900px;
        height: 600px;
        max-width: 350px;
        width: 100%;
    }
}